function layoutOverride() {
   if (document.querySelector('.container-fluid.about-page') != null){
      const wrapper = document.querySelector('.container-fluid.about-page').parentNode.parentNode
      const firstContainer = wrapper.children[0]
      const secondContainer = wrapper.children[1]


      if (
         firstContainer.querySelector('.container-fluid.about-page') != null &
         secondContainer.querySelector('.row.teams') != null
      ) {
         firstContainer.classList.add('customSpacer')
      }
   }
}

layoutOverride()