function mobileMenu() {
    const btn = document.querySelector('.btn-wrapper')
    btn.setAttribute('aria-label', btn.dataset.openText)
    
    btn.addEventListener('click', (e) => {
        // e.preventDefault()
        document.querySelector('.mobile-btn').classList.toggle('active')
        document.querySelector('.menu-mobile').classList.toggle('active')

        if (document.querySelector('.menu-mobile').classList.contains('active')){
            document.querySelector('body').style.overflow = 'hidden'
            document.querySelector('.header-nav__logo').style.opacity = '0'
            document.querySelector('.header-nav__logo').style.transitionDelay = '100ms'

            btn.setAttribute('aria-label', btn.dataset.closeText)
        } else {
            document.querySelector('body').style.overflow = 'auto'
            document.querySelector('.header-nav__logo').style.opacity = '100'
            document.querySelector('.header-nav__logo').style.transitionDelay = '600ms'

            btn.setAttribute('aria-label', btn.dataset.openText)
        }
    })

}

mobileMenu()
