function initCustomCursor() {
   //  const customCursor = document.createElement('div')
   //  document.body.appendChild(customCursor)
   //  customCursor.classList.add('customCursor')

    const customAnimCursor = document.createElement('div')
    customAnimCursor.setAttribute('class', 'hoveredCursor')
    
    const customCursorText = document.createElement('p')
    customCursorText.setAttribute('class', 'hoveredCursorText')

    customAnimCursor.appendChild (customCursorText)
    
    let customCursorHover = false
    
   //  document.addEventListener('mousemove', (e) => {
   //      customCursor.style.left = e.pageX + "px"
   //      customCursor.style.top = (e.pageY - scrollY) + "px"

   //      // if(window.innerWidth > 1024 && customCursor.style.display !== 'block' && !customCursorHover) {
   //      //     customCursor.style.display = 'block'
   //      // }
   //  })

    // Adding mouse hover effect on custom mouse (when hovering images)

    const images = document.querySelectorAll('figure')
    const btns = document.querySelectorAll('.btn')

   //  btns.forEach(btn => {
   //      btn.addEventListener('mouseenter', () =>{
   //          customCursor.style.visibility = 'hidden'
   //      })
   //      btn.addEventListener('mouseleave', () =>{
   //          if(window.innerWidth > 1024) {
   //              customCursor.style.visibility = 'visible'
   //          }
   //      })
   //  })

    images.forEach(img => {
        img.addEventListener('mouseenter', () => {
            if (img.parentElement.tagName === "A") {
                if(window.innerWidth > 1024) {
                  //   customCursor.style.display = 'none'
                    img.appendChild(customAnimCursor)
                    customCursorText.textContent = img.parentElement.dataset.linkTitle || 'Les mer'
                    customCursorHover = true
                }
            } else if (img.classList.contains('.teams-img-wrapper')) {
                if (img.dataset.hoverText !== "") {
                  //   customCursor.style.display = "none"
                }
            }
        })
        
        img.addEventListener('mousemove', (e) => {
            let imgRect = img.getBoundingClientRect()

            customAnimCursor.style.top = `${Math.floor(e.pageY - imgRect.top - window.scrollY)}px`
            customAnimCursor.style.left = `${Math.floor(e.pageX - imgRect.left)}px`
        })

        img.addEventListener('mouseleave', () => {
            if(window.innerWidth > 1024) {
               //  customCursor.style.display = 'block'

                if (img.querySelector('.customAnimCursor') !== null) {
                    img.removeChild(customAnimCursor)
                }
            } else {
               //  customCursor.style.display = 'none'
            }

            customCursorHover = false
        })
    })

    if (document.getElementById('bannerPlayButton')) {
        const playButton = document.querySelector('#bannerPlayButton')
        const closeButton = document.querySelector('#bannerCloseButton')
    
        playButton.addEventListener('mouseenter', () =>{
            // customCursor.style.visibility = 'hidden'
        })
        playButton.addEventListener('mouseleave', () =>{
            if(window.innerWidth > 1024) {
               //  customCursor.style.visibility = 'visible'
            }
        })
    
        closeButton.addEventListener('mouseenter', () =>{
            // customCursor.style.visibility = 'hidden'
        })
        closeButton.addEventListener('mouseleave', () =>{
            if(window.innerWidth > 1024) {
               //  customCursor.style.visibility = 'visible'
            }
        })
    }

    if (document.querySelector('.noCustomCursor')) {
        const noCustomCursorElements = document.querySelectorAll('.noCustomCursor')

        noCustomCursorElements.forEach(element => {
            element.addEventListener('mouseenter', () =>{
               //  customCursor.style.visibility = 'hidden'
            })
            element.addEventListener('mouseleave', () =>{
                if(window.innerWidth > 1024) {
                  //   customCursor.style.visibility = 'visible'
                }
            })
        })
    }
}

initCustomCursor()